.ipresens-text-color {
    color: #8c2bee !important;
}
.bg-primary {
    box-shadow: 1px 5px 24px 0 #8c2bee !important;
    background-color: #8c2bee !important;
}
.ipresens-btn-color {
    background-color: #8C2BEE;
    color: #FFFFFF;
}
.ipresens-btn-color:hover {
    background-color: #5c1d9a;
}
.ipresens-btn-add-pengumuman {
    background-color: #8C2BEE;
    padding: 10px 12px;
    border-radius: 4px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    float: right;
}
.ipresens-btn-add-pengumuman:hover,
.ipresens-save-button:hover
{
    cursor: pointer;
}
.ipresens-btn-cancel {
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    background-color: #FFFFFF;
    color: #591b96;
    padding: 8px 14px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
}
.ipresens-btn-revert
{
    border: 1px solid;
    border-radius: 25px;
    width: 50%;
    color: #7805e4;
    background-color: white;
    border-color: #7805e4;
    padding-top: 10px;
    padding-bottom: 6px;
}

.ipresens-btn-revert:hover
{
    color: #1B002F;
    cursor: pointer;
}

.ipresens-btn-cancel:hover,
.ipresens-cancel-button
{
    background-color: #FAFDFF;
    border-color: #7805e4;
    color: #7805e4;
    cursor: pointer;
}
.ipresens-btn-cancel-add {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    color: #3a138c;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
}
.ipresens-btn-cancel-add:hover {
    background-color: #8C2BEE;
    border-color: #7805e4;
    color: #ffffff;
}
.ipresens-btn-filter {
    color: #2d145e;
    background-color: #efe8ff;
    font-size: 14px;
    text-transform: capitalize;
    border: 1px solid #4f05e4;
    padding-top: 6px;
    padding-bottom: 6px;
}
.ipresens-btn-filter:hover {
    background-color: #8c2bee;
    color: #FFFFFF;
}
.ipresens-custom-btn-group {
    padding-left: 25px;
    padding-right: 25px;
    color: #2d145e;
    font-size: 14px;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
}
.ipresens-custom-btn-group:hover {
    padding-left: 25px;
    padding-right: 25px;
    color: #8c2bee;
    font-size: 14px;
    background-color: #FAFDFF;
    border-color: #8c2bee;
}
.ipresens-custom-btn-group:focus {
    padding-left: 25px;
    padding-right: 25px;
    color: #8c2bee;
    font-size: 14px;
    background-color: #FAFDFF;
    border-color: #8c2bee;
}

.ipresens-active-custom-btn-group {
    padding-left: 25px;
    padding-right: 25px;
    color: #8c2bee;
    font-size: 14px;
    background-color: #FAFDFF;
    border-color: #8c2bee;
}
.ipresens-btn-reset-filter {
    color: #491979 !important;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #dddddd;
    padding: 10px 10px;
}
.ipresens-btn-revert {
    color: #8c2bee !important;
    border: 1px solid #8C2BEE;
    font-size: 14px;
}
.ipresens-btn-reset-filter:hover {
    color: #8c2bee !important;
    border: 1px solid #8C2BEE;
    background-color: #F5E8FF;
    font-size: 14px;
    cursor: pointer;
}
.ipresens-btn-revert:hover {
    color: #8c2bee !important;
    border: 1px solid #8C2BEE;
    background-color: #F5E8FF;
    font-size: 14px;
    cursor: pointer;
}
.ipresens-import-button {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    padding: 8px 24px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #41146c;
    height: 100%;
}
.ipresens-import-button:hover {
    border-color: #8c2bee;
    cursor: pointer;
}
.ipresens-export-btn {
    border: none;
    background-color: #8c2bee;
    color: #FFFFFF;
    border-radius: 8px;
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}
.ipresens-export-btn:hover {
    cursor: pointer;
}
.ipresens-export-btn:disabled {
    background-color: #bf94ff;
    color: #FFFFFF;
}
.ipresens-save-button {
    border: none;
    border-radius: 6px;
    background-color: #8c2bee;
    color: #FFFFFF;
    padding: 10px 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
}
.ipresens-cancel-button {
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    background-color: #FFFFFF;
    color: #591b96;
    padding: 10px 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
}
.ipresens-image-empty {
    height: 100%;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
}
.ant-spin-dot-item {
    background-color: #8c2bee !important;
}
.ant-spin-text {
    color: #8c2bee !important;
}
.page-item.active .custom-page-link {
    background-color: #8c2bee !important;
    border-radius: 8px;
}
.page-item:first-child .custom-prev-next-link {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-right: 8px;
}
.page-item:last-child .custom-prev-next-link {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-left: 8px;
}
.swal2-styled.swal2-confirm {
    background-color: #8c2bee !important;
}
.swal2-styled.swal2-cancel {
    border: 1px solid #DDDDDD !important;
    border-radius: 6px;
    background-color: #FFFFFF !important;
    color: #591b96 !important;
    padding: 9px 10px;
    font-weight: 700;
}
.swal2-styled.swal2-cancel:hover {
    border-color: #7805e4 !important;
    color: #8c2bee !important;
}
.input-group {
    margin-bottom: 20px;
}
.ipresens-blue-light-info {
    color: #1b002f;
    background-color: #f5e8ff;
    padding: 12px;
    font-family: 'DM Sans';
}

.ipresens-success-info {
    color: #143B5E;
    background-color: #b99efe;
    padding: 12px;
    font-family: 'DM Sans';
}
.ipresens-delete-button {
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    background-color: #FFFFFF;
    color: #591b96;
    padding: 8px 14px;
}
.ipresens-delete-button:hover {
    cursor: pointer;
}
.ant-btn-primary {
    background-color: #8c2bee !important;
    color: #FFFFFF !important;
    border: none;
    border-color: transparent !important;
}
.ant-btn-primary:hover {
    background-color: #641da9 !important;
    border-color: #8c2bee !important;
}
.ant-btn:hover {
    border: 1px solid #8c2bee !important;
    color: #8c2bee !important;
}
@media only screen and (min-width: 500px) {
    .ipresens-input {
        height: 38px !important;
    }
}
.bg-image-search {
    background-image: url('../assets/icons/search.svg');
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 5%;
}
.progress-bar {
    background-color: #8c2bee;
}
.ipresens-btn-edit {
    background-color: #FFFFFF;
    border: #DDDDDD 1px solid;
    border-radius: 4px;
    color: #2d145e;
    font-weight: bold;
    width: 86px;
    height: 32px;
    margin-right: 5px;
    cursor: pointer;
    font-size: 13px;
}
.btn-edit:active {
    border-color: #864ff5;
    box-shadow: 1px 1px 9px #8771ea;
}
.ipresens-btn-edit:hover {
    color: #8c2bee;
    border: #8c2bee 1px solid;
    cursor: pointer;

}
@media only screen and (max-width: 450px) {
    .btn-group {
        padding: 0;
    }
    .ipresens-custom-btn-group {
        padding-right: 40px;
        padding-left: 40px;
        justify-content: center;
        display: flex;
    }
}
@media only screen and (min-width: 500px) {
    .ipresens-custom-btn-group {
        padding-right: 25px;
        padding-left: 25px;
        justify-content: center;
        display: flex;
    }
}