/* login v2 */
#input-password {
    width: 300px;
}
.loginv2-failed {
    background-color: #FFECE8;
    color: #E03616;
    padding: 16px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    border-radius: 8px;
}
.loginv2-success {
    background-color: #E8F2E7;
    color: #1E7E0E;
    padding: 16px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    border-radius: 8px;
}
.loginv2-div-img {
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vh;
}
.loginv2-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.loginv2-invalid {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #E03616;
}
.loginv2-row-logo {
    margin-bottom: 32px;
}
.loginv2-row-form {
    padding: 0px 80px;
}
.loginv2-button-login {
    text-transform: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
}
.loginv2-button-login:disabled {
    background-color: #F9F9F9 !important;
    color: #B6B6B6 !important;
    border-color: #EFEFEF !important;
}
.loginv2-label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #143B5E;
}
.loginv2-input {
    padding: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}
.loginv2-input-icon {
    background-color: #FFFFFF;
    border-left: none;
}
.loginv2-lupa-password {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8c2bee !important;
}
.loginv2-masuk-akun {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    color: #143B5E;
    margin-bottom: 32px;
    margin-top: 32px;
}
.loginv2-info {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #143B5E;
    text-align: center;
}
.loginv2-col-form {
    padding-top: 40px;
    background-color: #FFFFFF;
}
@media only screen and (max-width: 1439px) {
    .loginv2-row-form {
        padding: 0px 28px;
    }
    #input-password {
        width: 290px;
    }
}

@media only screen and (max-width: 1023px) {
    .loginv2-row-form {
        padding: 0px 28px;
    }
    #input-password {
        width: 220px;
    }
}

@media only screen and (max-width: 767px) {
    .loginv2-div-img {
        height: 224px;
    }
    .loginv2-row-logo {
        margin-bottom: 16px;
    }
    .loginv2-masuk-akun {
        margin-top: 16px;
    }
    .loginv2-col-form {
        padding-top: 32px;
    }
    #input-password {
        width: 340px;
    }
}
/*@media only screen and (max-width: 430px) {*/
/*}*/


.card-pricing.popular {
    z-index: 1;
    border-radius: 10px;
    border: 1px solid #DEDEDE;
    height: 100%;
}

.card-pricing .list-unstyled li {
    padding: .5rem 0;
    color: #ffffff;
}

.ribbon-container {
    --d: 0px; /* folded part */
    --c: #FF9C00; /* color */
    --f: 24px; /* ribbon font-size */
    z-index: 1;
    position: relative;
}

.ribbon-container::before {
    content: attr(data-ribbon);
    position: absolute;
    z-index: 1;
    font-size: var(--f);
    top: 0;
    right: 0;
    transform: translate(29.29%, -100%) rotate(45deg);
    color: #fff;
    text-align: center;
    transform-origin: bottom left;
    padding: 25px 35px calc(var(--d) + 6px);
    background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d)
    no-repeat var(--c);
    background-clip: padding-box;
    clip-path: polygon(0 0,100% 0,100% 100%,calc(100% - var(--d)) calc(100% - var(--d)),var(--d) calc(100% - var(--d)),0 100%);
    -webkit-mask: linear-gradient(135deg,transparent calc(50% - var(--d) * 0.707),#fff 0) bottom left,
    linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0)bottom right;
    -webkit-mask-size: 300vmax 300vmax;
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
}

body {
    background: #f2f2f2;
}


.parent-ribbon {
    overflow: hidden; /* required */
}

.ribbon {
    margin: 0;
    padding: 0;
    background: rebeccapurple;
    color:white;
    padding:1em 0;
    position: absolute;
    top:0;
    right:0;
    transform: translateX(30%) translateY(0%) rotate(45deg);
    transform-origin: top left;
}
.ribbon:before,
.ribbon:after {
    content: '';
    position: absolute;
    top:0;
    margin: 0 -1px; /* tweak */
    width: 100%;
    height: 100%;
    background: rebeccapurple;
}
.ribbon:before {
    right:100%;
}

.ribbon:after {
    left:100%;
}

.label-input {
    width: 25%;
    font-size: 1rem;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

.pl-body {
    padding-left: 80px;
}

.padding-body {
    padding-left: 80px;
    padding-right: 80px;
}

.drop-wrapper {
    border-style: solid;
    border-width: 2px;
    border-radius: 4px;
    background-color: #f5f5f5;
    border-color: #e9e9e9;
}

.drop-card {
    margin: 6px;
    background-color: #ffffff;
    border-radius: 4px;
}

.task-title-text {
    font-size: 16px;
    font-weight: 400;
}

@media only screen and (max-width: 900px) {
    .pl-body {
        padding-left: 0px;
    }
    .padding-body {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .label-input {
        width: 100%;
        font-size: 1rem;
    }
}

.card-input-element+.card {
    height: auto;
    color: var(--primary);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 2px solid transparent;
    border-radius: 4px;
}

.card-input-element+.card:hover {
    cursor: pointer;
}

.card-input-element:checked+.card {
    border: 2px solid var(--primary);
    -webkit-transition: border .3s;
    -o-transition: border .3s;
    transition: border .3s;
}

/*.card-input-element:checked+.card::after {*/
/*    content: '\e5ca';*/
/*    color: #AFB8EA;*/
/*    font-family: 'Material Icons';*/
/*    font-size: 24px;*/
/*}*/

.ant-steps-small .ant-steps-item-title {
    font-size: 12px;
}
