.button-pin {
    border-radius: 4px;
    height: 40px;
    background-color: #057CE4;
    border: none;
    cursor: pointer;
    color: #00a8ff;
}
.button-lokasi {
    margin-top: 20px;
}
.group-button {
    padding-left: 0;
}
@media only screen and (max-width: 767px) {
    #input-radius {
        width: 100%;
        height: auto;
        margin: auto;
    }
    .button-lokasi {
        margin-top: auto;
    }
    .group-button {
        margin-left: 15px;
    }
}