.button-edit {
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    padding: 4px 12px;
    cursor: pointer;
    font-size: 13px;
}


.button-delete {
    border: 1px solid #dc3546;
    border-radius: 4px;
    padding: 4px 12px;
    cursor: pointer;
    font-size: 13px;
    background-color: #dc3546;
    color: #fff;
}

.button-delete:hover {
    border: 1px solid #dc3546;
    border-radius: 4px;
    padding: 4px 12px;
    cursor: pointer;
    font-size: 13px;
    background-color: rgba(220, 53, 70, 0.82);
    color: #fff;
}

.button-edit:hover {
    border: 1px solid #8c2bee;
    border-radius: 4px;
    padding: 4px 12px;
}

.button-save-payment-type {
    border: 1px solid #8c2bee;
    border-radius: 4px;
    padding: 4px 12px;
    background-color: #8c2bee;
    color: #ffffff;
    font-size: 13px;
    cursor: pointer;
}
.button-save-payment-type:hover {
    border: 1px solid #3a138c;
    border-radius: 4px;
    padding: 4px 12px;
    background-color: #3a138c;
    color: #ffffff;
    font-size: 13px;
}

.button-cancel-payment-type {
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    padding: 4px 12px;
    font-size: 13px;
    cursor: pointer;
}
.button-cancel-payment-type:hover {
    border: 1px solid #3a138c;
    border-radius: 4px;
    padding: 4px 12px;
}

.card-payment-type {
    box-shadow: -1px 0 10px 3px rgba(0,0,0,0.05);
}