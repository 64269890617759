.nilai-th-style {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #143B5E;
    text-align: left;
}
.nilai-td-style {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #143B5E;
    text-align: left;
}