/* paginate */
.pagination-l {
    float: none;
}
.custom-page-link {
    border: none;
    background-color: #F5F5F5;
    color: #143B5E;
    height: 100%;
    padding: 10px 16px 8px;
}
.custom-prev-next-link {
    border: 1px solid #DDDDDD;
    border-radius: 8px;
}

.page-item.active .custom-page-link {
    background-color: #057CE4;
    border-radius: 8px;
}
.page-item:first-child .custom-prev-next-link {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-right: 8px;
}
.page-item:last-child .custom-prev-next-link {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-left: 8px;
}

/* list */
.scroll-horizontal {
    overflow-x: auto !important;
}
/* .react-datepicker-wrapper {
    padding-top: 8px !important;
} */
.tes:nth-child(even) {
    background-color: #f2f2f2;
}
.btn-reset-filter {
    color: #143B5E; font-size: 14px; padding-top: 6px; padding-bottom: 6px
}
.btn-reset-filter:hover {
    color: #057CE4; background-color: #E8F4FF; font-size: 14px; padding-top: 6px; padding-bottom: 6px
}
.bg-image-input {
    background-image: url('../../assets/icons/search.svg');
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 5%;
}

.filter-ml {
    padding-left: 12px;
}

.button-filter {
    color: #143B5E;
    background-color: #E8F4FF;
    font-size: 14px;
    text-transform: capitalize;
    border: 1px solid #057CE4;
    padding-top: 6px;
    padding-bottom: 6px;
}
.button-filter:hover {
    background-color: #057CE4;
    color: #FFFFFF;
}
.button-add {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    color: #143B5E;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
}
.button-add:hover {
    background-color: #FAFDFF;
    border-color: #057CE4;
    color: #057CE4;
}
.font-12 {
    font-size: 12px;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.success-color {
    color: #1E7E0E;
}
.warning-color {
    color: #FF5507;
}
.th-padding {
    padding: 12px 5px;
}
.border-table {
    overflow: auto;
    border-radius: 8px;
    border: 1px solid #DDDDDD;
}
.th-font {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #252B2B;
    text-align: left;
    background-color: #FAFDFF;
    border-bottom: 1px solid #DDDDDD;
}
.td-font {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #252B2B;
    border: none;
}

.custom-btn-group {
    padding-left: 25px;
    padding-right: 25px;
    color: #143B5E;
    font-size: 14px;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
}
.custom-btn-group:hover {
    padding-left: 25px;
    padding-right: 25px;
    color: #057CE4;
    font-size: 14px;
    background-color: #FAFDFF;
    border-color: #057CE4;
}

.active-custom-btn-group {
    padding-left: 25px;
    padding-right: 25px;
    color: #057CE4;
    font-size: 14px;
    background-color: #FAFDFF;
    border-color: #057CE4;
}

.blue-light-info {
    color: #143B5E;
    background-color: #d7e9fe;
    padding: 12px;
    font-family: 'DM Sans';
}

.warning-info {
    color: #143B5E;
    background-color: #FFF6E8;
    padding: 12px;
    font-family: 'DM Sans';
}

.success-info {
    color: #143B5E;
    background-color: #9EC5FE;
    padding: 12px;
    font-family: 'DM Sans';
}

.modal-header-reactstrap {
    background-color: #FAFDFF;
    color: #143B5E;
    font-size: 24px;
    font-family: 'DM Sans';
    font-weight: 700;
    line-height: 36px;
}
.padding-list {
    padding: 24px 24px 0px 24px !important;
}
.tombol-tambah {
    margin-right: 8px;
}

.font-detail-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #143B5E;
}
.font-detail-value {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #252B2B;
}
.font-edit-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #143B5E;
}
.font-edit-value {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #5D6F80;
}
.font-edit-label-2 {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #143B5E;
}
.font-edit-value-2 {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #5D6F80;
}
.col-name-filter {
    padding-left: 16px;
}

.ph-filter-date-left {
    padding: 8px 4px 8px 12px;
    border: 1px solid #DDDDDD;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #FFFFFF;
}
.ph-filter-date-left:hover {
    cursor: pointer;
}
.ph-filter-date-mid {
    width: 100%;
    padding: 9px 8px;
    height: 100%;
    font-size: 14px;
    background-color: #F9F9F9;
    font-weight: 500;
    color: "#143B5E";
    border: 1px solid #DDDDDD;
    border-left: 0px;
    border-right: 0px;
    background-color: #F9F9F9;
    color: #143B5E;
    margin: 0px;
}
.ph-filter-date-mid:hover {
    cursor: pointer;
}
.ph-filter-date-right {
    padding: 8px 12px 8px 4px;
    border: 1px solid #DDDDDD;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #FFFFFF;
}
.ph-filter-date-right:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .scroll-horizontal {
        overflow-x: auto !important;
    }
    .col-name-filter {
        padding-left: 0px;
    }
    .react-datepicker__input-container {
        margin-left: 0px;
    }
    .jam-masuk {
        width: 220px;
        text-align: left;
        padding-left: 135px;
    }
    .jam-masuk-header {
        margin: 0px;
        width: 280px;
        padding-left: 200px;
    }
    .padding-list {
        padding: 24px 14px 0px 24px !important;
    }
    .custom-btn-group {
        color: #143B5E;
        background-color: #FFFFFF;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 10px;
    }
    .width-filter {
        width: 100%;
    }
    .filter-ml {
        padding-left: 0px;
    }
    .filter-nama {
        padding-left: 30px;
    }
    .fixed-column-table {
        position: absolute;
        min-height: 70px;
        padding-top: 24px;
        /* *position: relative; */
    }
    .fixed-head-table {
        position: absolute;
        padding: 12px 8px;
        /* *position: relative; */
    }
    .pr-custom {
        padding-right: 0px;
    }

    .filter-paginate {
        margin-bottom: 8px;
    }
    .filter-name {
        margin-bottom: 8px;
        margin-left: 0px;
        padding-left: 25px;
    }
    .filter-button {
        margin-bottom: 8px;
    }
    .tombol-tambah {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 8px;
    }
    .filter-masuk {
        width: 20%;
    }
    .filter-belum-masuk {
        width: 35%;
    }
    .filter-belum-keluar {
        width: 35%;
    }
    .filter-terlambat {
        width: 25%;
    }
    .filter-izin {
        width: 20%;
    }
}
@media screen and (max-width: 576px) {
    .col-name-filter {
        padding-left: 0px;
    }
    .scroll-horizontal {
        overflow-x: auto !important;
    }
    .filter-paginate {
        margin-bottom: 8px;
    }
    .filter-name {
        margin-bottom: 8px;
        margin-left: 0px;
        padding-left: 25px;
    }
    .filter-button {
        margin-bottom: 8px;
    }
    .tombol-tambah {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 8px;
    }
    .filter-masuk {
        width: 20%;
    }
    .filter-belum-masuk {
        width: 35%;
    }
    .filter-belum-keluar {
        width: 35%;
    }
    .filter-terlambat {
        width: 25%;
    }
    .filter-izin {
        width: 20%;
    }
}
