.filter_nama {
    background-color: #f9f9f9;
    padding: 12px 10px;
    color: #5D6F80;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
.form_control {
    display: flex;
    flex-direction: column;
    padding: 6px 16px;
}
.form_control label {
    font-size: 14px;
    font-weight: 500;
}
.form_control input {
    font-size: 14px;
    padding: 8px 8px;
}
.form_control textarea {
    font-size: 14px;
    height: 100%;
    min-height: 100px;
    resize: none;
}