
.colorPickerWrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
  width: 350px;
  height: 300px;
}

.colorPicker {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.saturation {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  background: rgb(255, 0, 0);
  height: 100%;

  &__selector {
    position: absolute;
    top: 100%;
    left: 0;
    width: 8px;
    height: 8px;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 1.5px, rgba(0, 0, 0, .3) 0px 0px 1px 1px inset, rgba(0, 0, 0, .4) 0px 0px 1px 2px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  &__black, &__white {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__black {
    background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
  }

  &__white {
    background: linear-gradient(to right, #fff, rgba(0, 0, 0, 0));
  }
}

.hueWrapper {
  height: 8px;
  width: 100%;
  margin: 10px 0;
}

.hue {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: relative;
  background: linear-gradient(
                  to right,
                  hsl(0, 100%, 50%),
                  hsl(60, 100%, 50%),
                  hsl(120, 100%, 50%),
                  hsl(180, 100%, 50%),
                  hsl(240, 100%, 50%),
                  hsl(300, 100%, 50%),
                  hsl(360, 100%, 50%)
  );
  display: flex;
  align-items: center;

  &__selector {
    position: absolute;
    width: 8px;
    height: 16px;
    border-radius: 4px;
    background-color: white;
  }
}

.input {
  height: 38px;
  padding: 10px;
  border-radius: 4px;
  background-color: rgb(200, 200, 200);
  box-sizing: border-box;
  border: none;
  color: black;
  font-size: 14px;
  caret-color: red;
}