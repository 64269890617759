body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font: 12pt "DM Sans";
}
* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.page {
    width: 210mm;
    padding: 20mm;
    margin: 10mm auto;
    border-radius: 5px;
    background: white;
}
#content-list {
    width: 590px;
    font-size: 10px;
    margin: auto;
}

.subpage {
    padding: 1cm;
    border: 5px #ffffff solid;
    height: 257mm;
    outline: 2cm #ffffff solid;
}

@media only screen and (min-width: 641px){
    .subpage-list {
        padding-left: 10%;
        border: 5px #ffffff solid;
        height: 197mm;
    }
}
@media only screen and (max-width: 640px){
    .subpage-list {
        padding-left: 10%;
        border: 5px #ffffff solid;
        height: 360mm;
    }
}

@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 210mm;
        height: 297mm;
    }
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}
