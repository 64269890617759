#avatar {
    /* This image is 687 wide by 1024 tall, similar to your aspect ratio */
    /*background-image: url('http://i.stack.imgur.com/Dj7eP.jpg');*/

    /* make a square container */
    width: 90px;
    height: 90px;

    /* fill the container, preserving aspect ratio, and cropping to fit */
    background-size: cover;

    /* center the image vertically and horizontally */
    background-position: top center;

    /* round the edges to a circle with border radius 1/2 container size */
    border-radius: 50%;

    margin: 10px;


}

#avatar-square {
    /* This image is 687 wide by 1024 tall, similar to your aspect ratio */
    /*background-image: url('http://i.stack.imgur.com/Dj7eP.jpg');*/

    /* make a square container */
    width: 120px;
    height: 150px;

    /* fill the container, preserving aspect ratio, and cropping to fit */
    object-fit: contain;

    /* round the edges to a circle with border radius 1/2 container size */
    border-radius: 8px;

    margin: 10px;


}

.menu-header-title {
    font-weight: 500;
    font-size: 1.5rem;
    flex-grow: 0 !important;
    margin-left: 15px;
    width: 100%;
    border-bottom: 1px dashed #EFEFEF;
}

.header-title {
    font-weight: 500;
    font-size: 1.5rem;
    flex-grow: 0 !important;
    width: 100%;
}

.dropdown-menu-header .menu-header-content.btn-pane-right {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: left;
}
