.icon-info {
    cursor: pointer;
}
.modaling-style {
    top: 30%;
}
.show-hide {
    height: 38px;
    top: -35%;
}
.input-password {
    height: 38px;
}
.show-hide-button {
    background-color: #FFFFFF;
    border-left: none;
}

.responsive-img {
    max-width: 100%;
    height: auto;
}

.smallBoldText {
    font-size: small;
    font-weight: bold;
    width: 100%;
}

.tambah-shift  {
    float: right;
    color: white !important;
    text-transform: none;
}
.tambah-jabatan  {
    float: right;
    color: white !important;
    text-transform: none;
}
.tambah-outlet  {
    float: right;
    color: white !important;
    text-transform: none;
}
.tambah-term {
    float: right;
    color: white !important;
    text-transform: none;
    margin-top: 10px;
}

.tambah-export  {
    float: right;
    color: white !important;
    text-transform: none;
}


.btn-fltr-izin {
    width: 100% !important;
}

.btn-srch-izin {
    width: 100% !important;
}

.btn-fltr-lmbr {
    width: 100% !important;
    flex-wrap: nowrap;
}

.img-adj {
    height: 100%;
    max-height: 148px;
    max-width: 148px;
}

.btn-add-bpjs {
    float: right;
}

.btn-slct-status-payroll {
    width: 100% !important;
}

@media (max-width: 577px) {
    .btn-srch-pengumuman {
        width: 100% !important;
    }
    .btn-add-pengumuman {
        width: 100% !important;
    }
    .col-srch-shift {
        width: 100% !important;
    }
    .btn-srch-shift {
        width: 100% !important;
    }
    .input-srch-shift {
        min-height: 54px !important;
        max-height: 54px !important;
    }
    .btn-refresh-shift {
        max-height: 54px !important;
    }
    .btn-add-shift {
        width: 100% !important;
    }
    .form-toleran-jamker {
        width: 100% !important;
    }
    .col-search-jbtn,
    .btn-search-jbtn{
        width: 100% !important;
    }
    .form-search-jbtn {
        margin-right: 10px !important;
    }
    .btn-add-jbtn {
        width: 100% !important;
    }
    .col-search-dvs,
    .btn-search-dvs{
        width: 100% !important;
    }
    .form-add-jabatan-dvs {
        width: 100% !important;
    }
    .btn-search-otl {
        width: 100% !important;
    }
    .btn-add-otl {
        width: 100% !important;
        float: none;
    }
    .form-radius-add-otl {
        width: 100% !important;
        margin-bottom: 40px !important;
    }
    .label-radius {
        margin: 0;
    }
    .btn-pin-otl {
        width: 100% !important;
    }
    .btn-srch-lksabsn {
        width: 100% !important;
    }
    .form-add-lksabsn {
        width: 100% !important;
    }
    .btn-search-istilah {
        width: 100% !important;
    }
    .column-select-istilah {
        width: 100% !important;
        margin-top: 0 !important;
    }

    .button-search-istilah {
        width: 100% !important;
        padding-right: 10px;
    }

    .button-select-istilah {
        width: 100% !important;
    }

    .column-add-term {
        margin-top: 5px !important;
    }

    .button-add-istilah {
        width: 100% !important;
        float: none !important;
    }

    .btn-srch-izin {
        width: 100% !important;
    }

    .btn-fltr-izin {
        width: 100% !important;
    }

    .form-tgl-izin {
        width: 100% !important;
        max-width: 360px !important;
    }

    .col-exp-izin {
        width: 100% !important;
    }

    .btn-srch-lmbr {
        width: 100% !important;
    }

    .btn-fltr-lmbr {
        width: 100% !important;
    }

    .col-fltr-lmbr {
        width: 100% !important;
    }

    .form-tgl-lmbr {
        width: 100% !important;
        max-width: 360px !important;
    }

    .btn-srch-kasbon {
        width: 100% !important;
    }

    .form-tgl-kasbon {
        width: 100% !important;
        max-width: 360px !important;
    }

    .form-tgl-pembayaran {
        width: 100% !important;
        max-width: 360px !important;
    }

    .btn-srch-kbeban {
        width: 100% !important;
    }

    .form-tgl-kbeban {
        width: 100% !important;
        max-width: 360px !important;
    }

    .btn-slc-krywn {
        width: 100% !important;
    }

    .btn-import-krywn {
        width: 100% !important;
        margin-top: 8px;
    }

    .btn-add-krywn {
        width: 100% !important;
        margin-top: 8px;
    }

    .btn-tgl-absen {
        width: 100% !important;
        min-width: 250px !important;
    }

    .btn-fltr-absen {
        width: 100% !important;
        min-width: 270px !important;
    }

    .igr-fltr-absen {
        width: 100% !important;
    }

    .col-tgl-absen {
        width: 100% !important;
    }

    .col-fltr-absen {
        margin-bottom: 8px;
        margin-right: 5px !important;
    }

    .btn-add-absen {
        width: 100% !important;
        min-width: 270px;
    }

    .col-btn-add-absn {
        margin-top: 10px !important;
        justify-content: unset !important;
    }

    .form-tgl-absen {
        width: 100% !important;
        max-width: 360px !important;
    }

    .col-srch-absnbln {
        margin-bottom: 8px !important;
    }

    .btn-bln-absnbln {
        margin-bottom: 8px !important;
    }

    .btn-slct-thn-absnbln {
        width: 100% !important;
    }

    .btn-exp-absnbln {
        width: 100% !important;
    }

    .btn-srch-kunjungan {
        width: 100% !important;
    }

    .btn-tgl-kunjungan {
        width: 100% !important;
    }

    .btn-exp-kunjungan {
        width: 100% !important;
    }

    .btn-srch-knjgnhari {
        width: 100% !important;
    }

    .btn-tgl-knjgnhari {
        width: 100% !important;
    }

    .btn-exp-knjgnhari {
        width: 100% !important;
    }

    .btn-srch-knjgnmngu {
        width: 100% !important;
    }

    .btn-exp-knjgnmngu {
        width: 100% !important;
    }

    .btn-srch-knjgnbln {
        width: 100% !important;
    }

    .btn-exp-knjgnbln {
        width: 100% !important;
    }

    .btn-srch-tugas {
        width: 100% !important;
    }

    .btn-srch-tugas-done {
        width: 100% !important;
    }

    .btn-slct-bpjs {
        width: 100% !important;
    }

    .btn-add-bpjs {
        width: 100% !important;
    }

    .btn-slct-payroll {
        width: 100% !important;
    }

    .row-btn-payroll {
        margin-right: -15px !important;
    }
    .col-slct-payrol {
        width: 100% !important;
        padding-right: 0 !important;
    }
    .btn-updt-payroll {
        width: 100% !important;
    }
    .btn-exp-payroll {
        width: 100% !important;
    }

    .btn-srch-payroll-list {
        width: 100% !important;
        margin-bottom: 0px !important;
    }

    .row-btn-payroll-list {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }

    .btn-slct-status-payroll {
        width: 100% !important;
    }

    .col-srch-payroll {
        width: 100% !important;
    }

    .btn-tgl-exp-payroll {
        width: 100% !important;
        max-width: 360px !important;
    }

    .btn-filter-exp-payroll {
        width: 100% !important;
    }

    .btn-password-profile {
        width: 100% !important;
        margin-bottom: 30px;
    }

    .btn-social-header {
        width: 100% !important;
    }

    .btn-switch-setting-app {
        margin-left: 20px;
    }
    .btn-switch-setting-presensi {
        margin-left: 20px;
    }
    .btn-switch-setting-recognition {
        margin-left: 20px;
    }

    .btn-srch-user-setting {
        width: 100% !important;
    }

    .btn-refresh-user-setting {
        width: 100% !important;
    }

    .form-pw-add-user {
        width: 100% !important;
        margin-bottom: 40px !important;
        margin-top: 10px !important;
    }

    .btn-srch-user-lvl-setting {
        width: 100% !important;
    }

    .btn-refresh-user-lvl-setting {
        width: 100% !important;
    }

    .form-istirahat-max {
        width: 100% !important;
    }

    .form-tgl-nilai {
        width: 100% !important;
        max-width: 360px !important;
    }
}